import snd_dustyRustyDeath1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-death-1.flac";
import snd_dustyRustyDeath2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-death-2.flac";
import snd_dustyRustyHurt1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-1.flac";
import snd_dustyRustyHurt10 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-10.flac";
import snd_dustyRustyHurt2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-2.flac";
import snd_dustyRustyHurt3 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-3.flac";
import snd_dustyRustyHurt4 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-4.flac";
import snd_dustyRustyHurt5 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-5.flac";
import snd_dustyRustyHurt6 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-6.flac";
import snd_dustyRustyHurt7 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-7.flac";
import snd_dustyRustyHurt8 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-8.flac";
import snd_dustyRustyHurt9 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-hurt-9.flac";
import snd_dustyRustyJoinParty1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-join-party-1.flac";
import snd_dustyRustyJoinParty2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-join-party-2.flac";
import snd_dustyRustyLevelComplete1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-level-complete-1.flac";
import snd_dustyRustyLevelComplete2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-level-complete-2.flac";
import snd_dustyRustyLevelComplete3 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-level-complete-3.flac";
import snd_dustyRustyLevelComplete4 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-level-complete-4.flac";
import snd_dustyRustyLevelComplete5 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-level-complete-5.flac";
import snd_dustyRustyLookHere1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-look-here-1.flac";
import snd_dustyRustyLookHere2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-look-here-2.flac";
import snd_dustyRustyLookHere3 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-look-here-3.flac";
import snd_dustyRustyLookHere4 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-look-here-4.flac";
import snd_dustyRustyMisc1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-misc-1.flac";
import snd_dustyRustyMisc2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-misc-2.flac";
import snd_dustyRustyMisc3 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-misc-3.flac";
import snd_dustyRustyMisc4 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-misc-4.flac";
import snd_dustyRustyNearDeath1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-near-death-1.flac";
import snd_dustyRustyNearDeath2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-near-death-2.flac";
import snd_dustyRustyNewLevel1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-1.flac";
import snd_dustyRustyNewLevel10 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-10.flac";
import snd_dustyRustyNewLevel11 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-11.flac";
import snd_dustyRustyNewLevel12 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-12.flac";
import snd_dustyRustyNewLevel2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-2.flac";
import snd_dustyRustyNewLevel3 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-3.flac";
import snd_dustyRustyNewLevel4 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-4.flac";
import snd_dustyRustyNewLevel5 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-5.flac";
import snd_dustyRustyNewLevel6 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-6.flac";
import snd_dustyRustyNewLevel7 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-7.flac";
import snd_dustyRustyNewLevel8 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-8.flac";
import snd_dustyRustyNewLevel9 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-new-level-9.flac";
import snd_dustyRustyPickup1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-1.flac";
import snd_dustyRustyPickup10Health from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-10-health.flac";
import snd_dustyRustyPickup11Health from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-11-health.flac";
import snd_dustyRustyPickup2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-2.flac";
import snd_dustyRustyPickup3 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-3.flac";
import snd_dustyRustyPickup4 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-4.flac";
import snd_dustyRustyPickup5 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-5.flac";
import snd_dustyRustyPickup6 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-6.flac";
import snd_dustyRustyPickup7 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-7.flac";
import snd_dustyRustyPickup8 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-8.flac";
import snd_dustyRustyPickup9Health from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-pickup-9-health.flac";
import snd_dustyRustyTaunt1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-taunt-1.flac";
import snd_dustyRustyTaunt2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-taunt-2.flac";
import snd_dustyRustyTaunt3 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-taunt-3.flac";
import snd_dustyRustyTaunt4 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-taunt-4.flac";
import snd_dustyRustyTaunt5 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-taunt-5.flac";
import snd_dustyRustyTaunt6 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-taunt-6.flac";
import snd_dustyRustyTaunt7 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-taunt-7.flac";
import snd_dustyRustyTaunt8 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-taunt-8.flac";
import snd_dustyRustyWorried1 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-worried-1.flac";
import snd_dustyRustyWorried2 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-worried-2.flac";
import snd_dustyRustyWorried3 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-worried-3.flac";
import snd_dustyRustyWorried4 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-worried-4.flac";
import snd_dustyRustyWorried5 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-worried-5.flac";
import snd_dustyRustyWorried6 from "../../../resources/audio/characters/dusty-rusty/dusty-rusty-worried-6.flac";
import img_dustyRustyHead from "../../../resources/images/characters/dusty-rusty-head.png";
import img_dustyRustyLeftArm from "../../../resources/images/characters/dusty-rusty-left-arm.png";
import img_dustyRustyLeftHand from "../../../resources/images/characters/dusty-rusty-left-hand.png";
import img_dustyRustyRightArm from "../../../resources/images/characters/dusty-rusty-right-arm.png";
import img_dustyRustyRightHand from "../../../resources/images/characters/dusty-rusty-right-hand.png";
import img_dustyRustyTorso from "../../../resources/images/characters/dusty-rusty-torso.png";
import { Character } from "./Character";

export const DustyRusty: Character = {
  textures: {
    head: img_dustyRustyHead,
    leftArm: img_dustyRustyLeftArm,
    leftHand: img_dustyRustyLeftHand,
    rightArm: img_dustyRustyRightArm,
    rightHand: img_dustyRustyRightHand,
    torso: img_dustyRustyTorso,
  },

  sounds: {
    death: [snd_dustyRustyDeath1, snd_dustyRustyDeath2],
    hurt: [
      snd_dustyRustyHurt1,
      snd_dustyRustyHurt2,
      snd_dustyRustyHurt3,
      snd_dustyRustyHurt4,
      snd_dustyRustyHurt5,
      snd_dustyRustyHurt6,
      snd_dustyRustyHurt7,
      snd_dustyRustyHurt8,
      snd_dustyRustyHurt9,
      snd_dustyRustyHurt10,
    ],
    joinParty: [snd_dustyRustyJoinParty1, snd_dustyRustyJoinParty2],
    lookHere: [
      snd_dustyRustyLookHere1,
      snd_dustyRustyLookHere2,
      snd_dustyRustyLookHere3,
      snd_dustyRustyLookHere4,
    ],
    misc: [
      snd_dustyRustyMisc1,
      snd_dustyRustyMisc2,
      snd_dustyRustyMisc3,
      snd_dustyRustyMisc4,
    ],
    nearDeath: [snd_dustyRustyNearDeath1, snd_dustyRustyNearDeath2],
    newLevel: [
      snd_dustyRustyNewLevel1,
      snd_dustyRustyNewLevel2,
      snd_dustyRustyNewLevel3,
      snd_dustyRustyNewLevel4,
      snd_dustyRustyNewLevel5,
      snd_dustyRustyNewLevel6,
      snd_dustyRustyNewLevel7,
      snd_dustyRustyNewLevel8,
      snd_dustyRustyNewLevel9,
      snd_dustyRustyNewLevel10,
      snd_dustyRustyNewLevel11,
      snd_dustyRustyNewLevel12,
    ],
    pickupItem: [],
    pickupGun: [
      snd_dustyRustyPickup1,
      snd_dustyRustyPickup3,
      snd_dustyRustyPickup4,
      snd_dustyRustyPickup6,
      snd_dustyRustyPickup7,
      snd_dustyRustyPickup8,
    ],
    pickupMelee: [snd_dustyRustyPickup2, snd_dustyRustyPickup5],
    pickupHealth: [
      snd_dustyRustyPickup9Health,
      snd_dustyRustyPickup10Health,
      snd_dustyRustyPickup11Health,
    ],
    relief: [
      snd_dustyRustyLevelComplete1,
      snd_dustyRustyLevelComplete2,
      snd_dustyRustyLevelComplete3,
      snd_dustyRustyLevelComplete4,
      snd_dustyRustyLevelComplete5,
    ],
    taunts: [
      snd_dustyRustyTaunt1,
      snd_dustyRustyTaunt2,
      snd_dustyRustyTaunt3,
      snd_dustyRustyTaunt4,
      snd_dustyRustyTaunt5,
      snd_dustyRustyTaunt6,
      snd_dustyRustyTaunt7,
      snd_dustyRustyTaunt8,
    ],
    worried: [
      snd_dustyRustyWorried1,
      snd_dustyRustyWorried2,
      snd_dustyRustyWorried3,
      snd_dustyRustyWorried4,
      snd_dustyRustyWorried5,
      snd_dustyRustyWorried6,
    ],
  },
};
