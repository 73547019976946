// Credits to all the people involved in the game and the creators of assets we used
// If you use an asset from somewhere, make sure the author is credited here
export const CREDITS_TEXT = `
#DESIGN
Philip Hann
Simon Baumgardt-Wellander


#PROGRAMMING
Philip Hann
Simon Baumgardt-Wellander


#VOICE ACTORS
Andy—Andy Moreland
Chad—Michael Wiktorek
Cindy—Lawlfrats
Clarice, Kyle—Marisa DeNicolo-Hann
Clyde—Philip Hann
Dusty Rusty—@ICREATENOVELTY
John Johnson—Jeff Wellander
Lucky Jack—Cole Graham
Santa—Ted Wellander
Simon—Simon Baumgardt-Wellander
Takeshi—Rory Jackson
Wendy—Wendy Vang
Zombies—Kevin Kalb @sixsevenkevin
—Lawlfrats


#MUSIC
Michael Wiktorek
Simon Baumgardt-Wellander


#SOUND EFFECTS
Simon Baumgardt-Wellander
Morgan Thurlow
Philip Hann

With thanks to—
Fesliyan Studios
The Free Firearm Sound Library
Aturax Audio (aturaxaudio.com)
Alchemia Audio (alchemiaaudio.com)
Richard Humphreys (https://www.takomamedia.com/)


#ART
Philip Hann
Simon Baumgardt-Wellander

With thanks to:—
Kenny NL
PWL
Project Cordon Sprites
Panda Maru 
Nicnubill
Ayene Chan
Caym
FreePBR.com
GameTextures.com
3dtextures.me
juliovii.itch.io
loutpany
Starder



No zombies were harmed in the making of this game.
Some celery, walnuts, potatoes, a lemon, and an overripe melon were though.
`;
