import { MeleeWeaponStats } from "../MeleeWeaponStats";
import { Axe } from "./Axe";
import { BaseballBat } from "./BaseballBat";
import { Katana } from "./Katana";

// List of all melee weapons
export const MELEE_WEAPONS: Array<MeleeWeaponStats> = [
  Axe,
  BaseballBat,
  Katana,
];
