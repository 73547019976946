// Do this so that we can import all decorations from here
export * from "./bathroomDecorations";
export * from "./floorDecorations";
export * from "./hallwayDecorations";
export * from "./lobbyDecorations";
export * from "./maintenanceDecorations";
export * from "./redCarpetDecorations";
export * from "./shopDecorations";

// NOTE: Everything exported by this file is assumed to be a DecorationInfo by the preloaders.
// If you have something else you want to export, consider putting it in a different file.
