import img_shelf1 from "../../../../resources/images/environment/shops/shelf-1.png";
import img_shopCounter1 from "../../../../resources/images/environment/shops/shop-counter-1.png";
import img_shopCounter2 from "../../../../resources/images/environment/shops/shop-counter-2.png";
import img_shopCounter3 from "../../../../resources/images/environment/shops/shop-counter-3.png";
import { DecorationInfo } from "./DecorationInfo";

export const counter1: DecorationInfo = {
  imageName: img_shopCounter1,
  heightMeters: 3,
  isSolid: true,
  bodyInset: [0.3, 0.3],
};

export const counter2: DecorationInfo = {
  imageName: img_shopCounter2,
  heightMeters: 3,
  isSolid: true,
  bodyInset: [0.3, 0.3],
};

export const counter3: DecorationInfo = {
  imageName: img_shopCounter3,
  heightMeters: 3,
  isSolid: true,
  bodyInset: [0.3, 0.3],
};

export const shopShelf1: DecorationInfo = {
  imageName: img_shelf1,
  heightMeters: 1.4,
  isSolid: true,
  bodyInset: [0.3, 0.3],
};
